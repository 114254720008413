.ui-notification-container {
    position: fixed;
    z-index: 9999;
    top: 40px;
    right: 10px;
    width: 300px;
}

.ui-notification {
    cursor: pointer;
    -webkit-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    height: 40px;
    color: #fff;
    background: #428bca;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);
}

.ui-notification.killed {
    -webkit-transition: opacity ease 1s;
    -o-transition: opacity ease 1s;
    transition: opacity ease 1s;
    opacity: 0
}

.ui-notification>h3 {
    font-size: 14px;
    font-weight: 700;
    display: block;
    margin: 10px 10px 0;
    padding: 0 0 5px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, .3)
}

.ui-notification a {
    color: #fff
}

.ui-notification a:hover {
    text-decoration: underline
}

.ui-notification>.message {
    margin: 10px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-top: 3%;
}

.ui-notification.warning {
    color: #fff;
    background: #f0ad4e
}

.ui-notification.error {
    color: #fff;
    background: #d9534f
}

.ui-notification.success {
    color: #fff;
    background: #5cb85c
}

.ui-notification.info {
    color: #fff;
    background: rgb(114, 181, 103)
}

.ui-notification:hover {
    opacity: .7
}