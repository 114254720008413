.react-overlay-loader-spinner:before {
  border-top-color: var(--primary) !important;
}

.resetpassword{
  border-left-color: var(--status-fail);
  border-left-style: solid;
  border-left-width: 5px;
  height: 34px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resetpassword div{
  line-height: 34px;
  margin-left: 11px;
}


p {
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
}

.color1 {
  color: var(--primary);
}
label {
  font-weight: 100;
  font-size: 14px;
}
.page-header {
  margin-top: 20px;
}
h1, h2, h3, h4, h5, h6, .btn, .bg-primary, .navbar-inverse .navbar-nav>li>a, .nav-tabs>li>a, .ag-menu, .ag-header {
  font-family: sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}
h1, h2, h3 {
  color: var(--primary);
}
.signin-form {
  min-width: 330px;
  max-width: 400px;
  padding: 15px;
  width: 40%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.signin-form p, .signin-form .form-group {
  margin-bottom: 20px;
}

.signin-form .page-header {
  font-size: 22px;
  margin-bottom: 10px;
}

.signin-form input {
  height: 41px;
}

.signin-form .btn-lg {
  font-size: 14px;
}
.forget-pass {
  float: right;
  padding-bottom: 5px;
}

.btn-primary, .btn-success {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff !important;
}

.btn-primary:hover, .btn-success:hover {
  background-color: var(--primary) !important;
  color: #fff !important;
}


button:focus {
  outline: 0 !important;
}

.forget-pass a, .forget-password label {
  font-size: 16px;
}
a, a:visited, a:active {
  color: rgb(132, 141, 167);
}
a, span {
  text-decoration: none !important;
}

.signin-form .reset-password-buttons-container {
  display: flex;
}

.signin-form .reset-password-buttons-container .btn-block+.btn-block {
  margin-top: 0;
  margin-left: 10px;
}

@media only screen and (max-width: 900px) {
  .signin-form .reset-password-buttons-container {
    flex-direction: column;
  }
  .signin-form .reset-password-buttons-container .btn-block+.btn-block {
      margin-top: 5px;
      margin-left: 0;
  }
}

.signin-form .signin-form-heading {
  margin-bottom: 10px;
}

.signin-form .checkbox {
  font-weight: normal;
}

.signin-form .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  color: #555 !important;
}

.signin-form .form-control:focus {
  z-index: 2;
}
.signin-form {
  min-width: 330px;
  max-width: 400px;
  padding: 15px;
  width: 40%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.login-logo {
  display: block;
  margin: 0 auto 20px auto;
  width: 100%;
}
.input-password-container {
  position: relative
}

.input-password-container .icon-view-password, .input-password-container .icon-hide-password {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
  top: 50%;
  right: 10px;
  -ms-transform: translate(0, -50%);
  transform: translate(0,-50%);
}

.input-password-container input {
  padding-right: 8% !important;
}


.reset-password-buttons-container{
font-size: 14px;
line-height: 1.42857143;
font-family: sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
color: rgba(85, 85, 85, 0.94) !important;
box-sizing: border-box;
text-decoration: none;
outline: none;
display: flex;
}


.has-error  {
  color: #a94442 !important;
}

.has-error .form-control {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
