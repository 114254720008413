.loader {
  font-size: 14px;
  line-height: 1.42857143;
  font-family: sans-serif, "Helvetxica Neue", Helvetica, Arial, sans-serif !important;
  color: rgba(85, 85, 85, 0.94) !important;
  box-sizing: border-box;
  text-decoration: none;
  outline: 0px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
}

.spinerAnimation {
  font-size: 14px;
  line-height: 1.42857143;
  font-family: sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: rgba(85, 85, 85, 0.94) !important;
  box-sizing: border-box;
  text-decoration: none;
  outline: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader-top .loader {
  top: 20%;
}

.loader .row {
  display: flex;
}

.cell {
  background-color: var(--primary) !important;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  margin: 7px;
  animation: animation 1.5s linear infinite;
}

.a {
  animation-delay: 0s;
}

.b {
  animation-delay: 0.3s;
}

.c {
  animation-delay: 0.6s;
}

.d {
  animation-delay: 0.9;
}

.e {
  animation-delay: 1.2s;
}

@keyframes animation {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}